<template>
  <div class="campPage">
    <div class="campList">
      <div class="bg1">
        <img class="listImg" src="@/assets/img/trainingCamp/listIcon.png" mode="aspectFit" />
        <img class="stars stars1" src="@/assets/img/trainingCamp/stars.png" mode="aspectFit" />
        <img class="stars stars2" src="@/assets/img/trainingCamp/stars.png" mode="aspectFit" />
        <img class="stars stars3" src="@/assets/img/trainingCamp/stars.png" mode="aspectFit" />
        <img class="stars stars4" src="@/assets/img/trainingCamp/stars.png" mode="aspectFit" />
        <img class="stars stars5" src="@/assets/img/trainingCamp/stars.png" mode="aspectFit" />
        <div class="bg2">
          <div class="bg3">
            <div class="list">
              <div class="item" @click="godetail(item)" v-for="(item, index) in list" :key="index">
                <div class="itemContent">
                  <div class="itemContent_left">
                    <img class="itemImg" :src="item.cover" />
                    <div class="tips">
                      <span v-if="item.signStatus == 1">{{ item.stime }}后开始报名</span>
                      <span v-else-if="item.signStatus == 2">{{ item.etime }}后截止报名</span>
                      <span v-else-if="item.signStatus == 3">{{
                        item.openStatus == 3 ? '训练营已结束' : '报名已结束'
                      }}</span>
                    </div>
                  </div>
                  <div class="itemContent_right">
                    <div class="contentTitle elp">{{ item.title }}</div>
                    <div class="contentTips">
                      共<span class="num">{{ item.linkNumber }}</span
                      >节 <span v-if="item.signStatus != 1" class="icon">/</span
                      ><span v-if="item.signStatus != 1" class="num">{{ item.studentNumber }}</span>
                      <span v-if="item.signStatus != 1">人已报名</span>
                    </div>
                    <div class="contentPrice"><span class="icon">￥</span>{{ item.joinPrice }}</div>
                  </div>
                </div>
                <div class="itemBut" :class="item.signStatus != 2 && item.isSign != 1 ? 'have' : ''">
                  <span v-if="item.isSign == 1">已报名 </span>
                  <span @click.stop="goOrders(item)" v-else-if="item.signStatus == 2">我要报名 </span>
                  <span v-else>{{ signStatus[item.signStatus] }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="ifHave"
      width="30%"
      center
    >
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifHave = false">取消</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getListByTenantId } from '@/api/markcamp'
import { getInfo, getDomain } from '@/api/cookies'
import { signStatus } from '@/api/emun'
import Vue from 'vue'

import { OrderClient } from '@/api/orderClient'
let orderClient = new OrderClient()
export default {
  data() {
    return {
      pageSize: 9999,
      pageNum: 1,
      total: 0,
      list: [],
      userInfo: {},
      signStatus: signStatus,
      ifHave: false,
      xlyrow: {},
    }
  },
  async created() {
    this.userInfo = await getInfo()
    this.copyright = getDomain()
    // console.log(this.copyright, 'this.copyright')
    this.getList()
    // let a = this.countdowm(new Date('2022-07-21 00:00:00').getTime() / 1000 + ' ')
    // console.log(a, '11111111')
  },
  methods: {
    /* 获取列表 */
    getList() {
      getListByTenantId({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        userid: this.userInfo ? this.userInfo.id : undefined,
        tenantid: this.copyright ? this.copyright.eId : undefined,
      }).then((res) => {
        // for (let item of res.rows) {
        //   item.stime = this.countdowm(new Date(item.signStart).getTime() / 1000 + ' ')
        //   item.etime = this.countdowm(new Date(item.signEnd).getTime() / 1000 + ' ')
        // }
        res.rows.forEach((item) => {
          item.stime = this.countdowm(new Date(item.signStart).getTime() / 1000 + ' ')
          item.etime = this.countdowm(new Date(item.signEnd).getTime() / 1000 + ' ')
        })
        this.list = res.rows
        this.total = res.total
      })
    },
    async godetail(item) {
      this.xlyrow = item
      // if (!this.userInfo) {
      //   Vue.prototype.goLoginView(true)
      //   return
      // }
      this.$router.push(`/markcamp/index?id=${item.id}`)
      // if (item.isSign == 0 && item.signStatus == 2) {
      //   this.ifHave = true
      // } else {
      //   this.$router.push(`/markcamp/index?id=${item.id}`)
      // }
    },
    goOrders(item) {
      this.xlyrow = item
      this.ifHave = true
    },
    /* 立即购买 */
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(this.xlyrow.id, this.userInfo.id, this.xlyrow.title, 11, this.xlyrow.title)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg },
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    countdowm(timestamp) {
      // console.log(timestamp, 'timestamp')
      // let a = new Date(timestamp).getTime() / 1000 + ' '
      let self = ''

      let nowTime = new Date()
      let endTime = new Date(parseInt(timestamp) * 1000)
      let t = endTime.getTime() - nowTime.getTime()
      if (t > 0) {
        let day = Math.floor(t / 86400000)
        let hour = Math.floor((t / 3600000) % 24)
        let min = Math.floor((t / 60000) % 60)
        let sec = Math.floor((t / 1000) % 60)
        hour = hour < 10 ? '0' + hour : hour
        min = min < 10 ? '0' + min : min
        sec = sec < 10 ? '0' + sec : sec
        let format = ''
        if (day > 0) {
          format = `${parseInt(hour) + parseInt(day) * 24}小时${min}分`
          // format = `${parseInt(hour) + parseInt(day) * 24}小时${min}分${sec}秒`
        }
        if (day <= 0 && hour > 0) {
          format = `${hour}小时${min}分`
        }
        if (day <= 0 && hour <= 0) {
          format = `${min}分`
        }
        self = format
      } else {
        // self = self.endText
      }
      // console.log(self, 'self.content')
      return self
    },
  },
}
</script>
<style lang="less" scoped>
.campPage {
  width: 100%;
  background: url('~@/assets/img/trainingCamp/z2.png');
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 760px;
  padding-bottom: 23px;

  .campList {
    position: relative;
    .listImg {
      width: 114px;
      height: 122px;
      position: absolute;
      left: 70px;
      top: -50px;
    }
    .bg1 {
      width: 1244px;
      background: #b5b7dc;
      border: 1px solid #2b3f70;
      border-radius: 2px;
      // margin-left: 53px;
      margin: 0 auto;
      position: relative;
      .bg2 {
        width: 100%;
        background: #bce1e0;
        border: 1px solid #2b3f70;
        border-radius: 2px;
        margin: 12px 0px 0px -8px;
        .bg3 {
          width: 100%;
          background: #ffffff;
          border: 1px solid #2b3f70;
          box-shadow: 4px 2px 2px 1px rgba(92, 176, 225, 0.18);
          border-radius: 2px;
          margin: 14px 0px 0px -10px;
          padding: 40px 40px 15px 40px;
          /* 列表 */
          .list {
            width: 100%;
            min-height: 1000px;
            border: 3px solid rgba(108, 194, 175, 0.2);
            border-radius: 2px;
            padding: 43px 20px 18px 24px;
            .item {
              width: 1065px;
              height: 270px;
              background: url('~@/assets/img/trainingCamp/itemBg.png');
              background-size: 100% 100%;
              padding: 26px 36px 29px 25px;
              position: relative;
              display: flex;
              margin: 0 auto;
              margin-bottom: 42px;
              cursor: pointer;
              .itemImg {
                width: 100%;
                height: 100%;
                background: #b5b7dc;
                border-radius: 5px;
                flex-shrink: 0;
                margin-right: 21px;
              }

              .itemContent {
                width: 100%;
                display: flex;
                .itemContent_left {
                  position: relative;
                  width: 378px;
                  height: 215px;

                  margin-right: 40px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                  .tips {
                    width: 100%;
                    position: absolute;
                    height: 45px;
                    background: rgba(26, 106, 181, 0.63);
                    border-radius: 0px 0px 2px 2px;
                    text-align: center;
                    font-size: 23px;
                    font-family: PingFang SC;
                    // font-weight: 400;
                    color: #ffffff;
                    line-height: 45px;
                    bottom: 0;
                  }
                }
                .itemContent_right {
                  .contentTitle {
                    margin-top: 10px;
                    font-size: 42px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #1a6bb5;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 520px;
                  }
                  .contentTips {
                    font-size: 25px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #5e5e5f;
                    line-height: 17px;
                    margin-top: 34px;
                    .num {
                      color: #101010;
                    }
                    .icon {
                      margin: 0px 7px;
                    }
                  }
                  .contentPrice {
                    margin-top: 40px;
                    font-size: 48px;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #e94634;
                    .icon {
                      font-size: 31px;
                    }
                  }
                }
              }
            }
            .itemBut {
              position: absolute;
              bottom: 21px;
              right: 36px;
              width: 198px;
              height: 61px;
              border-radius: 17px;

              background: #1a6bb5;
              text-align: center;
              line-height: 61px;

              font-size: 31px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
            .have {
              background: #bbbaba;
            }
          }
        }
      }
    }
  }
  .stars {
    position: absolute;
    z-index: 100;
  }
  .stars1 {
    width: 34px;
    height: 43px;
    top: 79px;
    left: 463px;
  }
  .stars2 {
    width: 63px;
    height: 82px;
    top: 67px;
    right: 245px;
  }
  .stars3 {
    width: 34px;
    height: 43px;
    top: 87px;
    right: 167px;
  }
  .stars4 {
    width: 63px;
    height: 82px;
    left: 43px;
    top: 970px;
  }
  .stars5 {
    width: 34px;
    height: 43px;
    top: 1038px;
    right: 94px;
  }
}
</style>
